import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
@Component({
  selector: 'app-pay-fees-popup',
  templateUrl: './pay-fees-popup.component.html',
  styleUrls: ['./pay-fees-popup.component.scss'],
})
export class PayFeesPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<PayFeesPopupComponent>
) {}

  close(){
    this.dialogRef.close(true);
  }
}
