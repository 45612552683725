export const StorageConst = {
    user_details: 'user_details',
    user_id: 'user_id',
    access_token: 'access_token',
    refresh_token: 'refresh_token',
    university_country: 'university_country',
    university_country_list: 'university_country_list',
    current_ip_location: 'current_ip_location',
    all_countries: 'all_countries',
    upload_doc_restriction: 'upload_doc_restriction',
    current_location_country: 'current_location_country',
    passport_verification_details: 'passport_verification_details',
    eligibility_form: 'eligibilityFormV',
    compareCourses: 'CRZ_compare_courses'
}