import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/localStorage/localStorage.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { urlRoutes } from '../constants/url-route.constant';
@Injectable({
    providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {}

    canActivate(): boolean {
        if (!this.localStorageService.isAuthenticated()) {
            return true;
        } else {
            // Redirect to the login page if not authenticated
            this.navigationService.routeToPath(`${urlRoutes.mainLayout}`);
            return false;
        }
    }
}
