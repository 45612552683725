import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage/localStorage.service';
import { urlRoutes } from '../constants/url-route.constant';
import { NavigationService } from '../services/navigation/navigation.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService
    ) {}

    canActivate(): boolean {
        if (this.localStorageService.isAuthenticated()) {
            return true;
        } else {
            // Redirect to the login page if not authenticated
            // this.navigationService.routeToPath(`/${urlRoutes.authentication}/${urlRoutes.login}`);
        //    this.navigationService.routeToPath(`/${urlRoutes.authentication}/${urlRoutes.login}`);
            return true;
        }
    }
}
