import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToggleService {

    private isToggled = new BehaviorSubject<boolean>(false);
    public toggledBehavior$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>('open')

    get isToggled$() {
        return this.isToggled.asObservable();
    }

    toggle() {
        this.isToggled.next(!this.isToggled.value);
        this.toggledBehavior$.next('sss');
    }

}