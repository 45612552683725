export const urlRoutes = {
    home: '',
    authentication: 'authentication',
    landing_page: 'landing-page',
    login: 'login',
    profile: 'profile',
    manageuserlist: 'manage-user-list',
    notice: 'notice',
    webinar: 'webinar',
    viewNotice: 'view-notice',
    noticeList: 'all-notice',
    viewWebinar: 'view-webinar',
    webinarList: 'all-webinar',
    adduser: 'add-user',
    edituser: 'edit-user',
    signin: 'signin',
    authMou: 'auth/prelogin-mou',
    authMouFile: 'auth/file-upload',
    registration: 'register',
    resetPassword: 'reset-password',
    forgotPassword: 'forgot-password',
    confirmMail: 'confirm-email',
    lockScreen: 'lock-screen',
    logout: 'logout',
    dashboard: 'dashboard',
    blank_dashboard: '',
    success: 'success-page',
    about_us:'about-us',
    financial_result:'financial-results',
    corporate_governance:'corporate-governance',
    shareholding_pattern: 'shareholding-pattern',
    material_creditors :'material-creditors',
    other_disclosure :'other-disclosure',
    industry_report :'industry-report',
    prospectus :'prospectus',
    investor_contact :'investor-contact',
    terms_and_condition : 'terms-and-conditions',
    privacy_policy: 'privacy-policy',
    refund_policy : 'refund-policy',
    contact_us : 'contact-us',
    universityDetails: 'university-details',
    
    // Main layout
    mainLayout: 'agent',

    // Tenant
    tenant: 'tenant',
    createdTenantLink: 'admin/tenant',
    createTenant: 'admin/tenant/create',
    editTenant: 'admin/tenant/edit/:tenantId',
    // SubTenant
    subTenant: 'sub-tenant',
    createdSubTenantLink: 'admin/sub-tenant',
    createSubTenant: 'admin/sub-tenant/create',
    editSubTenant: 'admin/sub-tenant/edit/:subTenantId',
    roles: 'roles',
    permissions: 'permissions',
    users: 'users',
    urm_university: 'urm-university',
    payment: 'finance',
    calendar: 'calendar',
    entryrequirements: 'entry-requirements',
    pg_entry_requirements: 'pg-entry-requirements',
    ug_entry_requirements: 'ug-entry-requirements',
    resource: 'resource',
    app_history: 'applications-history',
    search_course: 'search-course',
    add_new_application: 'add-new-appllication',
    accomodation: 'accomodation',
    forex: 'forex',
    application_view: 'view-application',
    search_university: 'search-university'
};
