import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public languages: string[] = ['en', 'zh-cn'];
    private languageSubject = new BehaviorSubject<string>('');
    currentLanguage = this.languageSubject.asObservable();
    constructor(
        public translate: TranslateService,
        private cookieService: CookieService
    ) {
        let browserLang;
        this.translate.addLangs(this.languages);
        if (this.cookieService.check('lang')) {
            browserLang = this.cookieService.get('lang');
        } else {
            browserLang = translate.getBrowserLang();
        }
        translate.use(
            browserLang?.match(/en|zh-cn|de|it|ru/) ? browserLang : 'en'
        );
    }

    /***
     * Cookie Language set
     */
    public setLanguage(lang: any) {
        this.translate.use(lang);
        this.cookieService.set('lang', lang);
    }

    public setDefaultLanguage(lang: any) {
        // lang = { text: 'English', flag: '../../../assets/img/flag/english_flag.svg', lang: 'en' };
        // this.cookieService.set('lang', lang);
    }

    public getLanguage(lang: any) {
        return this.cookieService.get('lang');
    }

    changeLanguage(language: string) {
        this.languageSubject.next(language);
      }
}
