<div class="create-dialog-box scrollable-dialog pay-fees-dialog">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0 pay-fees-header">Payment Details</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <div class="subhead">
       <p>Pay by Net Banking , Debit Card , Credit Card</p>
    </div>

     <div class="subgroup-payment-group">
            <div class="ucas-application-card">
                <p class="ucas-application-heading text-center">UCAS APPLICATION</p>
                <p class="ucas-application-amount text-center">Rs.3,500</p>
            </div>
            <div class="visa-assistance">
                <p class="visa-assistance-heading text-center">VISA ASSISTANCE</p>
                <p class="visa-assistance-amount text-center">Rs.5,000</p>
            </div>
     </div>

</div>