import { LocalStorageService } from './../services/localStorage/localStorage.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../services/navigation/navigation.service';
import { HttpStatusCodeConstant } from '../constants/http-status-code';
import { urlRoutes } from '../constants/url-route.constant';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    
    constructor(
        private readonly storageService: LocalStorageService,
        private readonly toastrService: ToastrService,
        private readonly navigationService: NavigationService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === HttpStatusCodeConstant.unauthorized || error.status === HttpStatusCodeConstant.forbidden) {
                    this.storageService.clearAll();

                    let activeToast = this.toastrService.warning(
                        'Please re-login to continue',
                        'Session Expired!',
                        { timeOut: 2000 }
                    );

                    activeToast.onHidden.pipe().subscribe(() => {
                        let url = `${urlRoutes.authentication}/${urlRoutes.login}`;
                        this.navigationService.routeToPath(url);
                    });
                } else if (error.status !== 0) {
                    // this.toastrService.error(
                    //     'Code: ' +
                    //         error.error.code +
                    //         '<br>' +
                    //         error.error.message,
                    //     error.error.code,
                    //     {
                    //         closeButton: true,
                    //         enableHtml: true,
                    //         disableTimeOut: true,
                    //     }
                    // );

                    // this.toastrService.error('Sorry! Something went wrong!');
                } else {
                    this.toastrService.error(
                        error.message,
                        'Unexpected Error!',
                        {
                            closeButton: true,
                            enableHtml: true,
                            disableTimeOut: true,
                        }
                    );
                }

                return throwError(error);
            })
        );
    }
}
