import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'specialChar',
})
export class SpecialCharPipe implements PipeTransform {
    transform(value: string): any {
        // return value ? (
        //   value.replaceAll(';p', '')
        //     .replaceAll(';/p', '')
        //     .replaceAll('&amp', '')
        //     .replaceAll('&amp;', '')
        //     .replaceAll('amp;', '')
        //     .replaceAll(';amp', '')
        //     .replaceAll('lt;', '')
        //     .replaceAll(';lt', '')
        //     .replaceAll('lt', '')
        //     .replaceAll('gt', '')
        //     .replaceAll(';gt', '')
        //     .replaceAll('gt;', '')
        //     .replaceAll(';', '')
        //     .replaceAll('h1', '')
        //     .replaceAll('/h1', '')
        //     .replaceAll('strong', '')
        //     .replaceAll('/strong', '')
        //     .replaceAll(';/strong', '')
        //     .replaceAll(';/', '')
        //     .replaceAll('/', '')
        //     .replaceAll('#39;', "'")
        //     .replaceAll('#39', "'")
        //     .replaceAll('&nbsp;', '')
        //     .replaceAll('nbsp;', '')
        //     .replaceAll('nbsp', '')
        //     .replaceAll(';nbsp', '')
        //     .replaceAll('ul', '')
        //     .replaceAll(';ul', '')
        //     .replaceAll('ul;', '')
        //     .replaceAll('/ul', '')
        //     .replaceAll('li', '')
        //     .replaceAll(';li', '')
        //     .replaceAll('li;', '')
        //     .replaceAll('/li', '')
        //     .replaceAll('<p>', '')
        //   ) : '';

        if (value) {
            var entities = [
                ['amp', '&'],
                ['apos', "'"],
                ['#x27', "'"],
                ['#x2F', '/'],
                ['#39', "'"],
                ['#47', '/'],
                ['lt', '<'],
                ['gt', '>'],
                ['nbsp', ' '],
                ['quot', '"'],
            ];

            for (var i = 0, max = entities.length; i < max; ++i)
                value = value.replace(
                    new RegExp('&' + entities[i][0] + ';', 'g'),
                    entities[i][1]
                );
        }

        value = value
            .replaceAll('&amp;nbsp;', ' ')
            .replaceAll('&amp;quot;', '"')
            .replaceAll('&amp;rsquo;', "'")
            .replaceAll('&amp;lsquo;', "'")
            .replaceAll('&#39;', "'")
            .replaceAll('&ndash;', '-')
            .replaceAll('&amp;', '&');

        return value;
    }
}
